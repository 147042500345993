import React from 'react';

import Layout from 'src/components/layout/layout';
import MembershipUsers from 'src/components/membership-users/membership-users';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const MemberPage = () => {
  return (
    <Layout>
      <SEO title="Member" />
      <PrivateRoute>
        <MembershipUsers />
      </PrivateRoute>
    </Layout>
  );
};
export default MemberPage;
